/*
 * @description:

 */
// import VueRouter from '@/router/index.js'

import axios from "axios"; // 引入axios
import QS from "qs";
import Cookies from "js-cookie";
import { token_name } from "./common";
import VueRouter from "@/router/index";

let myAxios = axios.create({
  timeout: 1000 * 10, //请求超时
  paramsSerializer: function (params: any) {
    //序列化请求参数，避免get请求参数出现&,空格等识别错误的问题
    return QS.stringify(params, {
      indices: false,
    });
  },
});
myAxios.defaults.headers.post["Content-Type"] = "application/json";

// http request 拦截器
myAxios.interceptors.request.use(
  (config: any) => {
    /* const token = await getSync("token").then(res=>{
      return res.token
  }) */
    // let token = localStorage.getItem("user_token")
    // let token = Cookies.get("traToken")||window.sessionStorage.getItem("traToken")
    let token = Cookies.get(token_name);

    config.headers['adber-app-key'] = '2cn1wgmfidytin3jef3b'
      config.headers['adber-signature'] = 'qRBYybjRch7BdTxO5NTyGUd6mBvowNbsjahnAnpdI-BXc5sCrRt15XGr-hc9oY7C'
    // let token = Cookies.get('trade_token') || getSession('traToken')
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      
      config.headers.authorization = "Bearer " + token; //请求头加上token

      // config.headers.authorization = decodeURI(token) //请求头加上token
    }

    // 因为加了子账号功能，很多地方都要查整体数据出来。主要是分销端跟供应商端的列表接口需要整改。
    // 前端需要传companyId到后台
    // if (store.state.user.currentUser.companyId) {
    //   config.headers.CompanyId = store.state.user.currentUser.companyId
    // }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// http response 拦截器
myAxios.interceptors.response.use(
  (response) => {
    //拦截响应，做统一处理 +
    let code = response.status;
    let msg = response.data.message;
    // console.log('msg',msg)
    if (code !== 200 && msg) {
      //协议成功，请求内容不成功，统一提示错误信息，并返回
      // 不要有return 否则其他状态码下的后续逻辑都不会再处理
      // return;
    }
    let responseObj = {
      status_code: code,
      data: response.data,
    };

    return Promise.resolve(responseObj);
  },
  //接口错误状态处理，也就是说无响应时的处理
  (error) => {
    let status = error.response.status;
    if (status >= 500 && status < 600) {
      let errorObj = {
        status_code: status,
        msg: "service error",
      };
      return Promise.reject(errorObj);
    }
    switch (status) {
      case 401: //未通过鉴权 即未登录
     
        VueRouter.push({
          //路由设置到登录页
          path: "/login",
        });
        break;
      case 403:
        break;
    }
    type stringArrOrString = Array<string> | string;
    let messageArr: stringArrOrString = "";
    messageArr = error.response.data.message;

    let totalStr: string = "";
    if (typeof messageArr == "string") {
      totalStr = messageArr;
    } else {
      totalStr = messageArr.reduce((total: string, str: string) => {
        return total + str + " ";
      });
    }

    let errorObj = {
      status_code: status,
      msg: totalStr,
    };
    return Promise.reject(errorObj);
  }
);

export default myAxios;
