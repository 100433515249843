/*
 * @description: vuex统一入口
 * @author: XianPengFei
 * @lastEditors: XianPengFei
 * @Date: 2020-04-02 14:42:21
 * @LastEditTime: 2020-09-18 15:42:33
 * @Copyright: 1.0.0
 */

import Vue from 'vue'
import Vuex from 'vuex'


Vue.use(Vuex)

// let storeObj:any = {}
// let files = require.context('@/store/modules', true, /\.ts$/)

// files.keys().forEach(key => {
//   let newKey = key.replace(/(\.\/|\.ts)/g, '')
//   storeObj[newKey] = require(`./modules/${newKey}`).default
// })

// export default new Vuex.Store({
//   modules: storeObj,
// })

export default new Vuex.Store({});
