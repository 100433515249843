<template>
  <div>
    <div class="header" v-if="footerFlag">
      <header-nav></header-nav>
    </div>

    <div class="content">
      <router-view></router-view>
    </div>
    <div class="footer" v-if="footerFlag">
      <footer-nav></footer-nav>
    </div>
  </div>
</template>

<script>
import headerNav from "@/layout/header.vue";
import footerNav from "@/layout/footer.vue";
import { mapState } from "vuex";
export default {
  components: {
    headerNav,
    footerNav,
  },
  computed: {
    ...mapState({
      footerFlag: (state) => state.user.footerFlag,
    }),
  },
};
</script>

<style lang="less" scoped>
.content {
  height: auto;
}
</style>
