import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ViewUI from "view-design";

import "view-design/dist/styles/iview.css";

import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
  loading: require("@/assets/loading.gif"), //加载中图片，一定要有，不然会一直重复加载占位图
});
// 将api挂载到vue的原型上
import { commonApiObj } from "@/api/index";
console.log(commonApiObj);
Vue.prototype.$api = commonApiObj;
console.log(Vue.prototype);

//添加客服系统
declare const window: Window & { $crisp: any; CRISP_WEBSITE_ID: any };
window.$crisp = [];
const crisp_id =
  process.env.NODE_ENV === "production"
    ? "2c71901e-3975-41e0-9792-41396aa07604"
    : "303b3a5a-d3ba-42cd-8e81-2e5be0dda433";
window.CRISP_WEBSITE_ID = crisp_id;
(function () {
  const d = document;
  const s = d.createElement("script");
  s.src = "https://client.crisp.chat/l.js";
  s.async = true;
  d.getElementsByTagName("head")[0].appendChild(s);
})();

Vue.use(ViewUI);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
