/*
 * @description: api接口的统一出口

 */
import { camelToKebab } from '@/utils/lib'
let commonApiObj :any  = {}

let files = require.context('./modules', true, /\.ts$/)
files.keys().forEach((key:any) => {
  let newKey = key.replace(/(\.\/|\.ts)/g, '').toString()
  commonApiObj[
    camelToKebab(newKey) + 'Api'
  ] = require(`./modules/${newKey}`).default
})

export{ commonApiObj }
