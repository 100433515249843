<template>
  <div class="header-top flex-box">
    <div class="header-top-left flex-box">
      <div class="header-top-left-logo">
        <img
          class="header-top-left-logo-img curPointer"
          src="@/assets/logo2.png"
          @click="toIndex"
          alt
        />
      </div>
      <div class="header-top-left-text flex-box">
        <div class="header-top-left-text-item" @click="toPricing">Pricing</div>
        <div class="header-top-left-text-item">Contact Us</div>
        <div class="header-top-left-text-item">API Docs</div>
        <div class="header-top-left-text-item">User Manual</div>
      </div>
    </div>
    <div class="header-top-right flex-box">
      <div class="header-top-right-text">Free Thial</div>
      <div class="header-top-right-logo">
        <Dropdown @on-click="toLogin" v-if="!isLogin">
          <img
            class="header-top-right-logo-img"
            src="@/assets/login-unkown.svg"
            alt
          />
          <DropdownMenu slot="list">
            <DropdownItem name="shipber">login adber</DropdownItem>
            <DropdownItem name="retail">login retail</DropdownItem>
            <DropdownItem name="supply">login supply</DropdownItem>
          </DropdownMenu>
        </Dropdown>

        <Dropdown @on-click="toUserAction" v-else>
          <img class="header-top-right-logo-img" :src="loginUserUrl" alt />
          <DropdownMenu slot="list">
            <DropdownItem name="management">account management</DropdownItem>
            <DropdownItem name="logout">logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { UserStoreModule } from "@/store/modules/user";
const loginUser = require("@/assets/login/login-user.svg");
export default {
  computed: {
    isLogin() {
      if (UserStoreModule.token == "") {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      loginUserUrl: loginUser,
    };
  },
  methods: {
    toUserAction(param) {
      if (param == "logout") {
        //登出
        UserStoreModule.logOut();
      } else {
        this.$router.push({
          path: "/brand",
        });
      }
    },
    toLogin(param) {
      this.$router.push({
        path: "/login",
        query: {
          account: param,
        },
      });
    },
    toIndex() {
      this.$router.push({ path: "/index" });
    },
    toPricing() {
      this.$router.push({ path: "/pricing" });
    },
  },
};
</script>

<style lang="less" scoped>
.curPointer {
  cursor: pointer;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header-top {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 80px;
  padding-right: 80px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(119, 119, 119, 0.4);

  /deep/&-left {
    &-logo {
      padding-right: 72px;
      &-img {
        width: 130px;
        height: 30px;
      }
    }
    &-text {
      &-item {
        white-space: nowrap;
        cursor: pointer;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 21px;
        text-transform: capitalize;
        color: #000;
        margin-right: 45px;
      }
      &-item:hover {
        color: #1972f5;
      }
    }
  }
  &-right {
    &-text {
      white-space: nowrap;
      cursor: pointer;
      background: linear-gradient(89.91deg, #1972f5 -4.62%, #50d6e9 105.02%);
      border-radius: 18px;
      padding: 0 24px;
      margin-right: 32px;
      height: 36px;
      line-height: 36px;
      color: #fff;
    }
    &-logo {
      &-img {
        cursor: pointer;
      }
    }
  }
}
</style>
