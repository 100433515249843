
//短横线转驼峰：xx-xx-xx文件名转xxXxXx,
 const camelToKebab = function (str:string) {
    if (/-/.test(str)) {
      let arr = str.split('-')
      arr.forEach((item, index, arr) => {
        if (index !== 0) {
          arr[index] = item[0].toUpperCase() + item.slice(1)
        }
      })
      return arr.join('')
    } else {
      return str
    }
  }

  export{
    camelToKebab
  }