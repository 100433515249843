import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/index/index.vue";
import userApi from "@/api/modules/user"
import { UserStoreModule } from "@/store/modules/user";
Vue.use(VueRouter);

//不显示layout的路由
const notFooterShow = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register/index.vue"),
  },
];

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    redirect: "/index",
    component: HomeView,
    children: [
      {
        path: "/index",
        name: "index",
        component: () => import("@/views/main/index.vue"),
      },
      {
        path: "/pricing",
        name: "pricing",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import("@/views/pricing/index.vue"),
      },

      ...notFooterShow,
    ],
  },
  {
    path: "/brand",
    name: "brand",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("@/views/brand/index.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

function includesRoutes(route: string, routes: any): boolean {
  let include = false;

  routes.forEach((ele: any) => {
    if (ele.path == route) {
      include = true;
    }
  });

  return include;
}

router.beforeEach((to, from, next) => {
  //设置是否显示layout  部分路由页面不显示layout
  if (includesRoutes(to.path, notFooterShow)) {
    UserStoreModule.setFooterFlag(false);
  } else {
    UserStoreModule.setFooterFlag(true);
  }

  if(to.path == '/login'){//如果跳转到登录路由
      console.log(to)
      if(to.query.cookie){ //清除cookie模式
        UserStoreModule.setTraToken('')
        userApi.setTradeberToken('')
      }
  }

  next();
});

export default router;
