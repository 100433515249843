import Cookies from "js-cookie"
import { token_name} from "@/utils/common"
let setToken = function (token:string) {
  // if (isRemember) {
  //   //勾选记住我,
  //   Cookies.set('traToken', token, { expires: 7 })
  // } else {
  //   //没有选中记住我，
  //   Cookies.set('traToken', token, { expires: 1 })
  // }

    // Cookies.set('trade_token', token, { expires: 1,domain:'localhost' })
  // Cookies.set('trade_token', token, { expires: 1,domain:'.tradeber.com' })
  // Cookies.set('shipber_token', token, { expires: 1,domain:'.shipber.com' })
  // Cookies.set('shipber_token', token, { expires: 1,domain:'.shipber.app' })
  
  Cookies.set(token_name, token)
  // Cookies.set('traToken', token, { expires: 7,domain:'.tradeber.com' })
  // Cookies.set('traToken', token, { expires: 7,domain:'.shipber.com' })
}

export { setToken }