const isProduction = process.env.NODE_ENV === "production";

const tradeberRetailDevIndex =
  "https://dev-retail.tradeber.com/dashboard?from=account";

const tradeberRetailProIndex =
  "https://retail.tradeber.com/dashboard?from=account";

const tradeberSupplyDevIndex =
  "https://dev-supply.tradeber.com/dashboard?from=account";
// const tradeberSupplyDevIndex = "http://localhost:9992/dashboard?from=account";
const tradeberSupplyProIndex =
  "https://supply.tradeber.com/dashboard?from=account";
// const tradeberSupplyProIndex = 'http://localhost:9992/dashboard'
const tradeberRetailIndex = isProduction
  ? tradeberRetailProIndex
  : tradeberRetailDevIndex;

const tradeberSupplyIndex = isProduction
  ? tradeberSupplyProIndex
  : tradeberSupplyDevIndex;
// const tradeberSupplyIndex = 'https://localhost:9992/dashboard'
// const tradeberIndex = 'http://localhost:9991/dashboard'
const stageDevApi = "https://accounts.stage.shipber.com";
const stageProApi = "https://accounts.shipber.app";
const stageApi = isProduction ? stageProApi : stageDevApi;
// const stageApi = `https://accounts${isProduction ? "" : ".stage"}.shipber.app`;
// const stageApi = "https://accounts.shipber.app";
const tradeberRetailIdentityApi = "/retailIdentityApi";
// const url = "http://api.accounts.adber.tech"; // dev
//const url = "https://accounts.stage.shipber.app"; // stage
// const url = "https://accounts.shipber.app"; // production

export {
  stageApi,
  tradeberRetailIdentityApi,
  tradeberRetailIndex,
  tradeberSupplyIndex,
  isProduction,
};
