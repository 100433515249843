import userApi from "@/api/modules/user";
import { setToken } from "@/utils/logic";
import { LoginParam, registerParam } from "@/interfaces/user/userInterface";
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from "vuex-module-decorators";
import store from "@/store";
import VueRouter from "@/router/index";
@Module({ name: "user", dynamic: true, namespaced: true, store })
export default class UserStore extends VuexModule {
  // state
  public footerFlag = false; //layout显示状态
  public token = ""; //token
  public loginLoading = false; //登录加载显示状态

  // getter
  get getFooterFlag() {
    return this.footerFlag;
  }

  get getLoginLoading() {
    return this.loginLoading;
  }

  @Mutation
  setFooterFlag(newVal: boolean) {
    //设置layout显示状态
    this.footerFlag = newVal;
  }
  @Mutation
  setTraToken(token: string) {
    //设置token
    this.token = token;
    setToken(token);
  }
  @Mutation
  setLoginLoading(newVal: boolean) {
    //设置登录加载框显示状态
    this.loginLoading = newVal;
  }
  @Action
  logOut() {
    this.context.commit("setTraToken", ""); //先设置token为空
    VueRouter.push({
      //路由设置到登录页
      path: "/login",
    });
  }
  @Action({ rawError: true })
  userLogin(param: LoginParam) {
    // if(env.production){
    return new Promise((resolve, reject) => {
      userApi
        .login(param)
        .then((res: any) => {
          console.log(res);
          const data = res.data.data;
          this.context.commit("setTraToken", data.token);

          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
  }

  @Action({ rawError: true })
  userRegister(param: registerParam) {
    // if(env.production){
    return new Promise((resolve, reject) => {
      userApi
        .register(param)
        .then((res: any) => {
          console.log(res);
          const data = res.data.data;
          this.context.commit("setTraToken", data.token);
          resolve(res);
        })
        .catch((err: any) => {
          console.log(err);
          reject(err);
        });
    });
  }
}

export const UserStoreModule = getModule(UserStore);
