import axios from "@/utils/http";
import { stageApi ,tradeberRetailIdentityApi} from "@/utils/baseEnv";
import { LoginParam,registerParam } from "@/interfaces/user/userInterface";
import { isProduction } from "@/utils/baseEnv"
let jsonp = require('jsonp')

class User {
  //获取brand数据
  getBrand(){
    return axios
      .get(`${stageApi}/v1/users/profile`)
  }
  //登录接口
  login(param: LoginParam) {
    return axios
      .post(`${stageApi}/official-website/login`, param)
     
  }

  //注册接口
  register(param:registerParam){
    return axios
    .post(`${stageApi}/official-website/signup`, param)
  }
  //设置跨域token 
  setTradeberToken(token:string){
    // return axios
    //   .get(`${tradeberRetailIdentityApi}/Login/SetCookie?token=${token}`)
    
    let apiStr = `https://${
      isProduction ? 'pro' : 'dev'
    }-api.tradeber.com/retailGeteway/retail-identity4/Login/SetCookie?token=`
    return new Promise(resolve => {
      jsonp(`${apiStr}${token}`, { timeout: 1000 * 5 }, (res:any) => {
        console.log(res)
        resolve(res)
      })
    })

      // console.log(tradeberRetailIdentityApi)
      // return new Promise((resolve) =>{
      //   jsonp(
      //     `https://dev-api.tradeber.com/retailGeteway/retail-identity4/Login/SetCookie?token=${token}`,
      //     { timeout  : 1000*5},
      //     (res:any) => {
      //       console.log(res)
      //       resolve(res)
      //     }
      //   )
      // })
      

  }
}
const user = new User();
export default user;
