let getLocalStorage = function (key:string) {
    
    return window.JSON.parse(window.localStorage.getItem(key) || '')
  }

  let getSession = function (name:string) {
    if (window.opener && Object.getOwnPropertyNames(window.opener).length > 0) {
      return window.opener.sessionStorage.getItem(name)
    } else {
      return sessionStorage.getItem(name)
    }
  }

  const token_name =process.env.NODE_ENV === 'production' ? 'tra_token' : 'dev_tra_token'
  const imageDomain = "https://dev-resource.tradeber.com/account" // 资源图片库
  export {
    getLocalStorage,
    getSession,
    token_name,
    imageDomain
  }