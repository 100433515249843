<template>
  <div class="all">
    <div class="all-container">
      <div class="all-container-content flex-box">
        <div class="all-container-content-left">
          <div class="all-container-content-left">
            <div class="all-container-content-left-img">
              <img
                class="all-container-content-left-img-img"
                src="@/assets/logo2.png"
                alt
              />
            </div>
            <div class="all-container-content-left-textBig">
              155 N Riverview Dr, Anaheim Hills, CA 92808
            </div>
            <div class="all-container-content-left-text">
              © 2021 Shipber ® Inc. All Rights reserved Power by
            </div>
            <div class="all-container-content-left-text curPointer">
              Privacy Policy
            </div>
          </div>
        </div>
        <div class="all-container-content-right flex-box">
          <div class="all-container-content-right-conLeft">
            <div class="all-container-content-right-conLeft-title">
              Products
            </div>
            <div class="all-container-content-right-conLeft-item">
              Order Management
            </div>
            <div class="all-container-content-right-conLeft-item">
              Product Management
            </div>
            <div class="all-container-content-right-conLeft-item">
              Transportation Management
            </div>
            <div class="all-container-content-right-conLeft-item">
              Warehouse Management
            </div>
          </div>
          <div class="all-container-content-right-conRight">
            <div class="all-container-content-right-conRight-title">
              Contact
            </div>
            <div class="all-container-content-right-conRight-item curPointer">
              hello@adber.com
            </div>
            <div class="all-container-content-right-conRight-item curPointer">
              Contact Us
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.curPointer {
  cursor: pointer;
}
.flex-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.all {
  background: #1972f5;
  box-sizing: border-box;
  /deep/&-container {
    padding-bottom: 30px;
    padding-top: 70px;
    padding-left: 150px;
    padding-right: 150px;
    display: flex;
    justify-content: space-between;
    &-content {
      width: 80vw;
      &-left {
        &-img {
          margin-bottom: 70px;
          &-img {
          }
        }
        &-textBig {
          text-align: left;
          white-space: nowrap;
          font-family:  PingFangSC-Regular, PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;

          color: #ffffff;
          margin-bottom: 1.25rem;
        }
        &-text {
          text-align: left;
          white-space: nowrap;
          font-family:  PingFangSC-Regular, PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;

          color: #ffffff;
          margin-bottom: 1.25rem;
        }
      }
      &-right {
        width: 30vw;
        &-conLeft {
          &-title {
            white-space: nowrap;
            font-family:  PingFangSC-Regular, PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            color: #ffffff;
            margin-bottom: 25px;
          }
          &-item {
            color: #fff;
            font-size: 14px;
            margin-bottom: 1.25rem;
            white-space: nowrap;
          }
        }
        &-conRight {
          &-title {
            white-space: nowrap;
            font-family:  PingFangSC-Regular, PingFang SC;
            font-style: normal;
            font-weight: normal;
            font-size: 20px;
            line-height: 24px;
            text-transform: capitalize;
            color: #ffffff;
            margin-bottom: 25px;
          }
          &-item {
            color: #fff;
            font-size: 14px;
            white-space: nowrap;
            margin-bottom: 1.25rem;
          }
          &-item:hover {
            color: #1972f5;
          }
        }
      }
    }
  }
}
</style>
